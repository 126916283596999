
import Vue from "vue";
//import LangSwitcher from "@/components/settings/LangSwitcher.vue";
import store from "@/store";
import moment from "moment";

export default Vue.extend({
  name: "Login",

  // components: { LangSwitcher },

  data: () => ({
    logo: require("@/assets/images/logos/logo.png") as any,
    model: {
      email: "" as string,
      password: "" as string
    },
    errorMessage: "" as string,
    errorMessages: {} as any,
    showPassword: false as boolean,
    forgot: false as boolean
  }),

  computed: {
    copyrightYear() {
      return moment().year()
    },
    phone() {
      return process.env.VUE_APP_PHONE
    },
    PasswordType(): string {
      if (this.showPassword) {
        return "text";
      }
      return "password";
    },
    rules() {
      return {
        email: [
          (v: string): boolean | string =>
            !!v || (this.$t("auth.validation.required") as string)
        ],
        password: [
          (v: string): boolean | string =>
            !!v.length || (this.$t("auth.validation.required") as string)
        ]
      };
    },
    isManager(): boolean {
      const [, , manager] = document.location.href;
      return manager === "manager";
    }
  },

  beforeRouteEnter(from, to, next) {
    const hasCredentials = store.getters["authentication/hasAccessToken"];

    if (hasCredentials) {
      next("/");
    } else {
      next();
    }
  },

  methods: {
    async signIn(): Promise<void> {
      try {
        if (await (this.$refs.form as any).validate()) {
          const response = await this.$API.auth().login(this.model);

          await this.$store.dispatch("authentication/login", response);
          document.location.href = origin;
        }
      } catch (e) {
        this.errorMessage = e.message;
      }
    },
    async forgotPassword(): Promise<void> {
      try {
        const response: any = await this.$API
          .auth()
          .forgot({ email: this.model.email });

        await this.$store.dispatch("alert/showSuccess", response.message);
        this.forgot = false;
      } catch (e) {
        this.errorMessage = e.message;
      }
    },
    togglePasswordType(): void {
      this.showPassword = !this.showPassword;
      this.errorMessage = "";
    },
    toggleForgot(): void {
      this.errorMessage = "";
      this.forgot = !this.forgot;
    },
    submitForm(): void {
      if (this.forgot) {
        try {
          this.forgotPassword();
        } catch (e) {
          console.log(e);
        }
      } else {
        this.signIn();
      }
    }
  }
});
